/* Leaderboard.css */
.leaderboard-container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .leaderboard-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .leaderboard-list, .total-leaderboard-list {
    list-style-type: none;
    padding: 0;
  }
  
  .leaderboard-item, .total-leaderboard-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  .leaderboard-item span, .total-leaderboard-item span {
    margin-right: 20px;
  }
  
  .leaderboard-button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .leaderboard-button:hover {
    background-color: #0056b3;
  }
  